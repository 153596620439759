<!-- form mix2 -->
<template>
    <div class="vx-col w-11/12">
      <div class="vx-col w-11/12 ml-auto mr-auto">
        <span>
          <b v-if="customerId != null">Edit Customer</b>
          <b v-else>Create Customer</b>
        </span>
        <vs-tabs>
          <vs-tab label="General" class="pb-20">
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input v-if="data.codeExternal == ''" class="w-full readonly" label="Code" v-model="data.code" readonly/>
                <vs-input v-else class="w-full readonly" label="Code" v-model="data.codeExternal" readonly/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="NOO Code"
                  v-model="data.codeNoo"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="External Code"
                  v-model="data.codeExternal"
                  readonly
                />
              </div>
            </div>
            <!-- <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Territory*"
                  v-model="territoryData"
                  readonly
                />
              </div>
            </div> -->
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Territory*</label>
                <multiselect
                  v-model="territory.selected"
                  :options="territory.options"
                  placeholder="Type to search"
                  track-by="name"
                  label="name"
                  :max-height="125"
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                      >
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                      >
                    </div>
                  </template>
                </multiselect>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('TerritoryCustomer')"
                  >{{ errors.first("TerritoryCustomer") }}</span
                >
              </div>
            </div>
            <div v-if="!showBm" class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Sales*</label>
                <multiselect
                  v-model="salesPersonal.selected"
                  :options="salesPersonal.options"
                  placeholder="Type to search"
                  track-by="Name"
                  label="Name"
                  :max-height="125"
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.CodeExternal }} {{ props.option.Name }}</span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.CodeExternal }} {{ props.option.Name }}</span>
                    </div>
                  </template>
                </multiselect>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('SalesPersonal')"
                  >{{ errors.first("SalesPersonal") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Outlet Name*"
                  v-validate="'required'"
                  name="Name"
                  v-model="data.name"
                />
                <span class="text-danger text-sm" v-show="errors.has('Name')">{{
                  errors.first("Name")
                }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Date of Birth</label>
                <datepicker
                  name="Date of Birth"
                  label="Date of Birth"
                  :inline="false"
                  v-model="data.dateOfBirth"
                  placeholder="Select Date"
                ></datepicker>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="NIK*"
                  v-model="data.nik"
                  v-validate="'numeric|digits:16'"
                  name="NIK"
                  @input="data.npwp = data.nik"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('NIK')"
                  >{{ errors.first("NIK")}}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Nik Name" v-model="data.nik_name" @input="data.npwpName = data.nik_name" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Status</label>
                <Status :status="data.status" @data="setStatus"></Status>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input class="w-full readonly" label="Created On" v-model="data.created_at" readonly />
              </div>
            </div>

            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Distribution Channel Matrix*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="matrixCustomerGroup.selected"
                      :options="matrixCustomerGroup.options"
                      placeholder="Type to search"
                      track-by="hierarchy_code"
                      label="hierarchy_code"
                      :max-height="125"
                      :custom-label="customLabelHierarchy"
                      :allow-empty="false"
                    >
                      <span slot="noResult">Oops! No data found</span>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.hierarchy_code }} {{ props.option.hierarchy_name }}</span>
                        </span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.hierarchy_code }} {{ props.option.hierarchy_name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('DistributionChannelMatrix')"
                  >{{ errors.first("DistributionChannelMatrix") }}</span
                >
              </div>
            </div>

            <div v-if="showBm" class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Branch Manager*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="salesPersonal.selected"
                      :options="salesPersonal.options"
                      placeholder="Type to search"
                      track-by="Name"
                      label="Name"
                      :max-height="125"
                    >
                      <span slot="noResult">Oops! No data found</span>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.CodeExternal }} {{ props.option.Name }}</span>
                        </span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.CodeExternal }} {{ props.option.Name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('SalesPersonal')"
                    >{{ errors.first("SalesPersonal") }}</span
                  >
                </div>
              </div>
            </div>

            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Customer Group 1*</label>
                <div class="vx-row">
                  <div class="vx-col w-full readonly">
                    <multiselect
                      v-model="customerGroup1.selected"
                      :options="customerGroup1.options"
                      placeholder="Type to search"
                      track-by="name"
                      label="name"
                      :max-height="125"
                      :custom-label="codeWithName"
                      :disabled="true"
                    >
                    </multiselect>
                  </div>
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('CustomerGroup1')"
                  >{{ errors.first("CustomerGroup1") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Customer Group 2*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="customerGroup2.selected"
                      :options="customerGroup2.options"
                      placeholder="Type to search"
                      track-by="name"
                      label="name"
                      :max-height="125"
                      :custom-label="codeWithName"
                      :disabled="true"
                    >
                    </multiselect>
                  </div>
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('CustomerGroup2')"
                  >{{ errors.first("CustomerGroup3") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Customer Group 3*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="customerGroup3.selected"
                      :options="customerGroup3.options"
                      placeholder="Type to search"
                      track-by="name"
                      label="name"
                      :max-height="125"
                      :custom-label="codeWithName"
                      :disabled="true"
                    >
                    </multiselect>
                  </div>
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('CustomerGroup3')"
                  >{{ errors.first("CustomerGroup3") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Pricing Group*</label>
                <multiselect
                  v-model="pricingGroup.selected"
                  :options="pricingGroup.options"
                  placeholder="Type to search"
                  track-by="name"
                  label="name"
                  :max-height="125"
                  :custom-label="codeWithName"
                  :disabled="true"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Risk Category*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="riskCategory.selected"
                      :options="riskCategory.options"
                      placeholder="Type to search"
                      track-by="code"
                      label="code"
                      :max-height="125"
                      :custom-label="codeWithDescription"
                    >
                    </multiselect>
                  </div>
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('RiskCategory')"
                  >{{ errors.first("RiskCategory") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Payment Type*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="paymentType.selected"
                      :options="paymentType.options"
                      placeholder="Type to search"
                      :max-height="125"
                      :disabled="status_disabled"
                    >
                      <span slot="noResult">Oops! No data found</span>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title"
                            >{{ props.option }}</span
                          >
                        </span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title"
                            >{{ props.option }}</span
                          >
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('PaymentType')"
                  >{{ errors.first("PaymentType") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Payment Term*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="paymentTerm.selected"
                      :options="paymentTerm.options"
                      placeholder="Type to search"
                      track-by="name"
                      label="name"
                      :max-height="125"
                      :disabled="true"
                    >
                      <span slot="noResult">Oops! No data found</span>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title"
                            >{{ props.option.code }} {{ props.option.name }}</span
                          >
                        </span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title"
                            >{{ props.option.code }} {{ props.option.name }}</span
                          >
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('PaymentTerm')"
                  >{{ errors.first("PaymentTerm") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  :class="classCL"
                  label="Credit Limit*"
                  v-model="data.customerLimit.amount"
                  name="CreditLimit"
                  :readonly="statusReadonlyCL"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('CreditLimit')"
                  >{{ errors.first("CreditLimit") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Cash Limit*"
                  v-model="data.cashLimit"
                  name="CashLimit"
                  :readonly="true"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('CashLimit')"
                  >{{ errors.first("CashLimit") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Delivery Type*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="deliveryType.selected"
                      :options="deliveryType.options"
                      placeholder="Type to search"
                      track-by="name"
                      label="name"
                      :max-height="125"
                    >
                      <span slot="noResult">Oops! No data found</span>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('DeliveryType')"
                  >{{ errors.first("DeliveryType") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Priority*</label>
                <multiselect
                  v-model="priority.selected"
                  :options="priority.options"
                  placeholder="Type to search"
                  :max-height="125"
                  :disabled="true"
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option }}</span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option }}</span>
                    </div>
                  </template>
                </multiselect>
                <span class="text-danger text-sm" v-show="errors.has('Priority')">{{
                  errors.first("Priority")
                }}</span>
              </div>
            </div>
            <!-- <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Note"
                  name="Note"
                  v-model="data.note"
                />
              </div>
            </div> -->
            <!-- <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Reason"
                  name="Reason"
                  v-model="data.reason"
                />
              </div>
            </div> -->
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Region SD*</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="regionSd.selected"
                      :options="regionSd.options"
                      placeholder="Type to search"
                      track-by="code"
                      label="code"
                      :max-height="125"
                      :custom-label="codeWithDescription"
                    >
                    </multiselect>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('RegionSd')"
                      >{{ errors.first("RegionSd") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Status Block</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <multiselect
                      v-model="StatusBlock"
                      :options="StatusBlockOption"
                      placeholder="Type to search"
                      track-by="code"
                      label="code"
                      :max-height="125"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <b>History Approval</b>
                <template >
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in this.data.reason">
                    <vs-td class="whitespace-no-wrap">
                      {{ tr }}
                    </vs-td>
                  </vs-tr>
                </template>
              </div>
            </div> -->
            <div class="vx-row">
              <div class="vx-col w-full">
                <label class="vs-input--label">Notes</label>
                <vs-textarea class="w-full" v-model="data.notes"/>
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">History Approval & Notes</vs-divider>
            <vs-table
              stripe
              border
              description
              :sst="true"
              :data="data.historyNotes"
            >
              <template slot="thead">
                <vs-th>Time</vs-th>
                <!-- <vs-th>Type</vs-th> -->
                <vs-th>User</vs-th>
                <vs-th>Notes</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td class="whitespace-no-wrap">{{ formatDateTime(tr.CreatedAt) }}</vs-td>
                  <!-- <vs-td class="whitespace-no-wrap">{{ tr.type }}</vs-td> -->
                  <vs-td class="whitespace-no-wrap">{{ tr.personal_name }}</vs-td>
                  <vs-td class="whitespace-no-wrap">{{ tr.notes }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>
          <vs-tab label="Address">
            <address-component
              :data="data.address"
              @data="setDataAddress"
            ></address-component>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Transport Zone"
                  v-model="data.address.transportZone"
                  disabled
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="RT / RW (Format 000/000)"
                  v-model="data.rtRw"
                  @keypress="isNumber($event)"
                  name="RT/RW"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('RT/RW')"
                  >{{ errors.first("RT/RW") }}</span
                >
              </div>
            </div>
            <!-- <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Street"
                  v-model="data.street"
                />
              </div>
            </div> -->
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Street No"
                  v-model="data.streetNo"
                />
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Lat Long">
            <div class="tab-text">
              <map-component :data="data.map" @data="setDataMap"></map-component>
            </div>
          </vs-tab>
          <vs-tab label="Contact">
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Contact Name*"
                  v-model="data.contactName"
                />
                <span class="text-danger text-sm" v-show="errors.has('ContactName')">{{
                  errors.first("ContactName")
                }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Phone*"
                  v-model="data.phone"
                  v-validate="'required|numeric|max:13|min:10'"
                  name="Phone"
                />
                <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
                  errors.first("Phone")
                }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Mobile*"
                  v-model="data.mobile"
                  v-validate="'numeric|max:13|min:10'"
                  name="Mobile"
                />
                <span class="text-danger text-sm" v-show="errors.has('Mobile')">{{
                  errors.first("Mobile")
                }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Email"
                  v-model="data.email"
                  v-validate="'email'"
                  name="Email"
                />
                <span class="text-danger text-sm" v-show="errors.has('Email')">{{
                  errors.first("Email")
                }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Fax"
                  v-model="data.fax"
                  v-validate="'numeric'"
                  name="Fax"
                />
                <span class="text-danger text-sm" v-show="errors.has('Fax')">{{
                  errors.first("Fax")
                }}</span>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Tax & Invoice">
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Max Open Bill* (0 = unlimited)"
                  v-model="data.maxOpenBill"
                  @keypress="isNumber($event)"
                  name="MaxOpenBill"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('MaxOpenBill')"
                  >{{ errors.first("MaxOpenBill") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Invoice Model*</label>
                <br />
                <ul class="centerx">
                  <li>
                    <vs-radio v-model="data.invoiceModel" vs-value="When Shipping" disabled
                      >When Shipping</vs-radio
                    >&nbsp;&nbsp;&nbsp;
                  </li>
                  <li>
                    <vs-radio v-model="data.invoiceModel" vs-value="Separated" disabled
                      >Separated</vs-radio
                    >
                  </li>
                </ul>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('InvoiceModel')"
                  >{{ errors.first("InvoiceModel") }}</span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Tax Collector</label>
                <vs-switch
                  color="dark"
                  icon-pack="feather"
                  vs-icon-on="icon-check-circle"
                  vs-icon-off="icon-slash"
                  v-model="data.isTaxCollector"
                  disabled
                >
                  <span slot="on">YES</span>
                  <span slot="off">NO</span>
                </vs-switch>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Tax Exempt</label>
                <vs-switch
                  color="dark"
                  icon-pack="feather"
                  vs-icon-on="icon-check-circle"
                  vs-icon-off="icon-slash"
                  v-model="data.isTaxExempt"
                  disabled
                >
                  <span slot="on">YES</span>
                  <span slot="off">NO</span>
                </vs-switch>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">PKP</label>
                <vs-switch
                  color="dark"
                  icon-pack="feather"
                  vs-icon-on="icon-check-circle"
                  vs-icon-off="icon-slash"
                  v-model="data.isPkp"
                >
                  <span slot="on">YES</span>
                  <span slot="off">NO</span>
                </vs-switch>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Gross up</label>
                <vs-switch
                  color="dark"
                  icon-pack="feather"
                  vs-icon-on="icon-check-circle"
                  vs-icon-off="icon-slash"
                  v-model="data.gross_up"
                >
                  <span slot="on">YES</span>
                  <span slot="off">NO</span>
                </vs-switch>
              </div>
            </div>
            <template>
              <div v-if="data.isPkp == true">
                <div class="vx-row mb-6">
                  <div class="vx-col w-full ml-6">
                    <label class="vs-input--label">Tax Type</label>
                    <div class="vx-row">
                      <div class="vx-col w-full">
                        <multiselect
                          v-model="taxType.selected"
                          :options="taxType.options"
                          placeholder="Type to search"
                          track-by="name"
                          label="taxType"
                          :max-height="125"
                        >
                          <span slot="noResult">Oops! No data found</span>
                          <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                              <span class="option__title"
                                >{{ props.option.code }} {{ props.option.name }}</span
                              >
                            </span>
                          </template>

                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title"
                                >{{ props.option.code }} {{ props.option.name }}</span
                              >
                            </div>
                          </template>
                        </multiselect>
                        <span class="text-danger text-sm" v-show="errors.has('taxType')">{{errors.first("taxType")}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full ml-6">
                    <label class="vs-input--label">NPWP Type</label>
                    <multiselect
                      v-model="npwpType.selected"
                      :options="npwpType.options"
                      placeholder="Type to search"
                      :max-height="125"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full ml-6">
                    <vs-input
                      class="w-full"
                      label="NPWP*"
                      v-model="data.npwp"
                      name="NPWP"
                    />
                    <!-- <vs-input
                      class="w-full"
                      label="NPWP"
                      v-model="data.npwp"
                      name="NPWP"
                      @input="autoFormatNPWP"
                    /> -->
                    <span
                    class="text-danger text-sm"
                    v-show="errors.has('NPWP')"
                    >{{ errors.first("NPWP") }}</span
                  >
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full ml-6">
                    <vs-input
                      class="w-full"
                      label="NPWP Name*"
                      v-model="data.npwpName"
                    />
                    <span
                    class="text-danger text-sm"
                    v-show="errors.has('NpwpName')"
                    >{{ errors.first("NpwpName") }}</span>
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full ml-6">
                    <vs-input
                      class="w-full"
                      label="SPPKP*"
                      v-model="data.sppkp"
                      name="SPPKP"
                    />
                    <span
                    class="text-danger text-sm"
                    v-show="errors.has('Sppkp')"
                    >{{ errors.first("Sppkp") }}</span>
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full ml-6">
                    <vs-input
                      class="w-full"
                      label="Tax Address*"
                      v-model="data.taxAddress"
                      name="TaxAddress"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('TaxAddress')">{{
                      errors.first("TaxAddress")
                    }}</span>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full ml-6">
                    <vs-input
                      class="w-full"
                      label="Domisili"
                      v-model="data.domisili"
                      name="domisili"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('Domisili')">{{
                      errors.first("Domisili")
                    }}</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full ml-6">
                    <label class="vs-input--label">Tax Type</label>
                    <div class="vx-row">
                      <div class="vx-col w-full">
                        <multiselect
                          v-model="taxType.selected"
                          :options="taxType.options"
                          placeholder="Type to search"
                          track-by="name"
                          label="taxType"
                          :max-height="125"
                        >
                          <span slot="noResult">Oops! No data found</span>
                          <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                              <span class="option__title"
                                >{{ props.option.code }} {{ props.option.name }}</span
                              >
                            </span>
                          </template>

                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title"
                                >{{ props.option.code }} {{ props.option.name }}</span
                              >
                            </div>
                          </template>
                        </multiselect>
                        <span class="text-danger text-sm" v-show="errors.has('taxType')">{{errors.first("taxType")}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full ml-6">
                    <vs-input
                      class="w-full"
                      label="NPWP*"
                      v-model="data.npwp"
                      name="NPWP"
                    />
                    <span
                    class="text-danger text-sm"
                    v-show="errors.has('NPWP')"
                    >{{ errors.first("NPWP") }}</span
                  >
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full ml-6">
                    <vs-input
                      class="w-full"
                      label="NPWP Name*"
                      v-model="data.npwpName"
                    />
                    <span
                    class="text-danger text-sm"
                    v-show="errors.has('NpwpName')"
                    >{{ errors.first("NpwpName") }}</span>
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full ml-6">
                    <vs-input
                      class="w-full"
                      label="Tax Address*"
                      v-model="data.taxAddress"
                      name="TaxAddress"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('TaxAddress')">{{
                      errors.first("TaxAddress")
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
          </vs-tab>
          <vs-tab label="Upload">
            <vs-divider style="width: 100%; margin-left: 0%">KTP Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span>KTP Image</span>
              </div>
              <div class="vx-col w-full">
                <input
                  name="file_ktp"
                  class="w-full inputx"
                  type="file"
                  ref="file_ktp"
                  accept=".jpg, .jpeg, .png"
                  @change="onImageChange($event, 'ktp')"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full" @click="showImage('KTP')">
                <img style="max-height: 170px; max-width: auto" :src="data.urlKtp" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">Outlet Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span>Outlet Image</span>
              </div>
              <div class="vx-col w-full">
                <input
                  name="file_outlet"
                  class="w-full inputx"
                  type="file"
                  ref="file_outlet"
                  accept=".jpg, .jpeg, .png"
                  @change="onImageChange($event, 'outlet')"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <img style="max-height: 170px; max-width: auto" :src="data.urlOutlet" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">NPWP Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span>NPWP Image</span>
              </div>
              <div class="vx-col w-full">
                <input
                  name="file_npwp"
                  class="w-full inputx"
                  type="file"
                  ref="file_npwp"
                  accept=".jpg, .jpeg, .png"
                  @change="onImageChange($event, 'npwp')"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full" @click="showImage('NPWP')">
                <img style="max-height: 170px; max-width: auto" :src="data.urlNpwp" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">SPPKP Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span>SPPKP Image</span>
              </div>
              <div class="vx-col w-full">
                <input
                  name="file_sppkp"
                  class="w-full inputx"
                  type="file"
                  ref="file_sppkp"
                  accept=".jpg, .jpeg, .png"
                  @change="onImageChange($event, 'sppkp')"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full" @click="showImage('SPPKP')">
                <img style="max-height: 170px; max-width: auto" :src="data.urlSppkp" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">Invoice Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span>Invoice Image</span>
              </div>
              <div class="vx-col w-full">
                <input
                  name="file_invoice"
                  class="w-full inputx"
                  type="file"
                  ref="file_invoice"
                  accept=".jpg, .jpeg, .png"
                  @change="onImageChange($event, 'invoice')"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <img style="max-height: 170px; max-width: auto" :src="data.urlInvoice" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">CL Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span>CL Image</span>
              </div>
              <div class="vx-col w-full">
                <input
                  name="file_cl"
                  class="w-full inputx"
                  type="file"
                  ref="file_cl"
                  accept=".jpg, .jpeg, .png"
                  @change="onImageChange($event, 'cl')"
                  disabled
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <img style="max-height: 170px; max-width: auto" :src="data.urlCl" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">Additional File</vs-divider>
            <!-- additional file -->
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <span>Additional File</span>
              </div>
              <div class="vx-col w-full">
                <input
                  name="file_additional"
                  class="w-full inputx"
                  type="file"
                  ref="file_additional"
                  accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                  multiple="multiple"
                />
              </div>
            </div>
            <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
              <table class="vs-table vs-table--tbody-table">
                <template v-for="(tr, i) in data.additionalFile">
                  <tr
                    class="tr-values vs-table--tr tr-table-state-null selected"
                    v-bind:key="tr.name"
                  >
                    <!-- <td class="td vs-table--td">{{ tr.doc_type }}</td> -->
                    <td class="td vs-table--td">{{ tr.name }}</td>
                    <td class="td vs-table--td">
                      <vx-tooltip text="Download" v-if="tr.path != ''">
                        <vs-button
                          type="line"
                          icon-pack="feather"
                          @click.stop="downloadFileAwsS3(tr.path)"
                          icon="icon-download"
                        />
                      </vx-tooltip>
                    </td>
                    <td class="td vs-table--td">
                      <template>
                        <vx-tooltip text="Delete">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            color="red"
                            @click.stop="handleDeleteAttachment(tr.ID, i)"
                            icon="icon-trash"
                          />
                        </vx-tooltip>
                      </template>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </vs-tab>
          <vs-tab label="Virtual Account">
            <template v-if="statusApproval != '2'">
              <p style="color: red;">Available when Customer Active</p>
            </template>
            <template v-else>
              <div class="vx-row mb-2">
                <div class="vx-col w-6/12">
                  <label class="vs-input--label">Bank</label>
                </div>
                <div class="vx-col w-3/12">
                  <label class="vs-input--label">VA Number</label>
                </div>
              </div>
              <div class="vx-col sm:w-12/12 w-full" v-for="(tr, indextr) in data.dataVa" :key="indextr">
                <div class="vx-row mb-2">
                  <div class="vx-col w-6/12">
                    <multiselect
                      v-model="data.dataVa[indextr].bank"
                      :options="bank.options"
                      :max-height="110"
                      :show-labels="false"
                      label="Bank"
                      placeholder="Bank"
                      track-by="Name"
                    >
                      <span slot="noResult">Oops! No data found</span>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.Name }}</span>
                        </span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.Name }}</span>
                        </div>
                      </template>
                    </multiselect>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('DataVa')"
                      >{{ errors.first("DataVa") }}
                    </span>
                  </div>
                  <div class="vx-col w-5/12">
                    <vs-input
                      class="w-full"
                      v-model="data.dataVa[indextr].account_number"
                      name="VA Number"
                      placeholder="VA Number"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('VA Number')"
                      >{{ errors.first("VA Number") }}
                    </span>
                  </div>
                  <div class="vx-col w-1/12">
                    <div
                      class="vx-col w-full"
                      style="text-align: center; margin: auto; margin-bottom: 0"
                    >
                      <vs-button
                        v-if="indextr != 0"
                        style="display: table-cell"
                        v-on:click="handleRemoveItem(indextr)"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-minus"
                      ></vs-button>
                      <vs-button
                        v-else
                        style="display: table-cell"
                        v-on:click="handleAddItem()"
                        color="success"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                      ></vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <!-- <vs-button v-if="statusApproval == '2'"
              class="mt-20" @click="handleUpdateVirtualAccount">Update Virtual Account
            </vs-button> -->
            <br><br><br><br><br>
          </vs-tab>
        </vs-tabs>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <!-- <vs-button v-if="statusApproval == '0' || statusApproval == '03'" class="mr-3 mb-2" @click="handleSubmit">Submit & Send for Approval</vs-button> -->
          <vs-button v-if="statusApproval == '2'" class="mr-3 mb-2" @click="handleSubmit">Update</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleClose"
            >Close</vs-button
          >
        </div>
      </div>
      <vs-prompt
        :active.sync="activeModalImage"
      >
        <div class="con-exemple-prompt">
          <img style="max-height: 800px; max-width: 900px; margin:auto; display: block;" :src="urlShow" />
        </div>
        <div>
          <vs-input
            class="w-full"
            :label="labelDialog"
            v-model="valueDialog"
            readonly
          />
        </div>
      </vs-prompt>
    </div>
  </template>
  <script>
  import PerfectScrollbar from "vue-perfect-scrollbar";
  import PaymentMethod from "./form-component/PaymentMethod";
  import BussinessPartner from "./form-component/BussinessPartner";
  import Status from "./form-component/Status";
  import PriorityType from "./form-component/PriorityType";
  import AddressComponent from "@/components/master/Address";
  import Datepicker from "vuejs-datepicker";
  import MapComponent from "./form-component/Map";
  import moment from "moment";

  export default {
    components: {
      PerfectScrollbar,
      PaymentMethod,
      BussinessPartner,
      Status,
      PriorityType,
      AddressComponent,
      Datepicker,
      MapComponent,
    },
    props: {
      customerId: {
        type: Number,
      },
      statusApproval: {
        type: String,
      },
      territoryData: {
        type: String,
      },
    },

    data() {
      return this.initialState();
    },
    methods: {
      formatDateTime(s){
        const m = moment.parseZone(s);
        return m.format('YYYY-MM-DD HH:mm:ss')
      },
      setDataMap(val) {
        this.data.map = val;
      },
      onImageChange(e, type) {
        console.log("on image change")
        const file = e.target.files[0];
        console.log(file)
        if (type == "ktp") {
          this.data.img_ktp = file;
          this.data.urlKtp = URL.createObjectURL(file);
        } else if (type == "invoice") {
          this.data.img_invoice = file;
          this.data.urlInvoice = URL.createObjectURL(file);
        } else if (type == "npwp") {
          this.data.img_npwp = file;
          this.data.urlNpwp = URL.createObjectURL(file);
        } else if (type == "sppkp") {
          this.data.img_sppkp = file;
          this.data.urlSppkp = URL.createObjectURL(file);
        } else if (type == "outlet") {
          this.data.img_outlet = file;
          this.data.urlOutlet = URL.createObjectURL(file);
        // } else if (type == "cl") {
        //   this.data.img_cl = file;
        //   this.data.urlCl = URL.createObjectURL(file);
        } else if (type == "additional") {
          this.data.img_additional = e.target.files;
        }
      },
      showImage(type) {
        if (type == "KTP") {
          this.urlShow = this.data.urlKtp
          this.valueDialog = this.data.nik_name + " / " + this.data.nik
        } else if (type == "NPWP") {
          this.urlShow = this.data.urlNpwp
          this.valueDialog = this.data.npwp+ " / " + this.data.taxAddress
        } else if (type == "SPPKP") {
          this.urlShow = this.data.urlSppkp
          this.valueDialog = this.data.npwp+ " / " + this.data.taxAddress
        }

        this.labelDialog = type
        this.activeModalImage = true
        console.log(type)
      },
      codeWithDescription ({ description, code }) {
        if (description != undefined) {
          return `${code} - ${description}`
        }
        return `${code}`
      },
      codeWithName ({ name, code }) {
        if (name != undefined) {
          return `${code} - ${name}`
        }
        return `${code}`
      },
      customLabelHierarchy({ hierarchy_code, hierarchy_name }) {
        if (hierarchy_name != undefined) {
          return `${hierarchy_code} - ${hierarchy_name}`
        }
        return `${hierarchy_code}`
      },
      initialState() {
        return {
          urlShow: "",
          activeModalImage: false,
          valueDialog: "",
          labelDialog: "",
          baseUrl: "/api/oms/v1/sfa-master/customer",
          baseUrlUpdate: "/api/oms/v1/sfa-master/customer-web",
          baseUrlMatrixCg: "/api/oms/v1/sfa-master/matrix-customer-group-selected",
          baseUrlMatrixCustomerGroup: "/api/oms/v1/sfa-master/matrix-customer-group",
          dataLoaded: false,
          status_disabled: false,
          classCL: "w-full",
          statusReadonlyCL: false,
          tabError: "",
          credit_limit_ori: 0,
          showBm: false,
          data: {
            ccName: "",
            historyApproval: [],
            historyNotes: [],
            notes: "",
            dataVa: [
              {
                "bank": [],
                "account_number": ""
              }
            ],
            img_ktp: null,
            img_outlet: null,
            img_npwp: null,
            img_sppkp: null,
            img_invoice: null,
            img_additional: null,
            maxOpenBill: "0",
            created_at: "auto",
            codeNoo: "auto generate",
            matrixCustomerGroupID: 0,
            parentID: null,
            customerGroupId: null,
            customerCategoryId: null,
            paymentTermId: null,
            paymentType: null,
            paymentMethodId: null,
            pricingGroupId: null,
            deliveryTypeId: null,
            customerGroupId1: null,
            customerGroupId2: null,
            customerGroupId3: null,
            dateOfBirth: null,
            code: "auto generate",
            codeExternal: "",
            name: "",
            legalName: "",
            searchName: "",
            contactName: "",
            phone: "",
            mobile: "",
            email: "",
            password: "",
            fax: "",
            isPkp: true,
            isTaxCollector: true,
            isTaxExempt: true,
            gross_up: true,
            npwp: "",
            tdp: "",
            siup: "",
            npwpName: "",
            sppkp: "",
            domisili: "",
            taxAddress: "",
            nik: "",
            nik_name: "",
            photo: "",
            urlKtp: "",
            urlNpwp: "",
            urlSppkp: "",
            urlInvoice: "",
            urlOutlet: "",
            urlCl: "",
            invoiceModel: "When Shipping",
            priorityType: "Platinum",
            customerLimit: {
              amount: 0,
            },
            address: {
              address: "",
              postalCode: "",
              countryName: "",
              countryID: 0,
              provinceName: "",
              provinceID: 0,
              cityName: "",
              cityID: 0,
              transportZone: "",
              districtName: "",
              districtID: 0,
              subdistrictName: "",
              subdistrictID: 0,
              showWarningAddress: false,
            },
            map: {
              latitude: "",
              longitude: "",
            },
            rtRw: "",
            street: "",
            streetNo: "",
            note: "",
            // reason: "",
            riskCategoryCode: "",
            regionSdCode: "",
            additionalFile: [],
            cashLimit: 0
          },
          bank: {
            selected: null,
            options: [],
          },
          city: {
            id: null,
            provinceId: null,
            name: "",
            transportZone: "",
          },
          customerCategory: {
            selected: null,
            options: [],
          },
          taxType: {
            selected: null,
            options: [],
          },
          customerGroup1: {
            selected: null,
            options: [],
          },
          customerGroup2: {
            selected: null,
            options: [],
          },
          customerGroup3: {
            selected: null,
            options: [],
          },
          paymentTerm: {
            selected: null,
            options: [],
          },
          paymentType: {
            selected: null,
            options: ["Cash",  "Credit"],
          },
          pricingGroup: {
            selected: null,
            options: [],
          },
          deliveryType: {
            selected: null,
            options: [],
          },
          territory: {
            selected: null,
            options: [],
          },
          salesPersonal: {
            selected: null,
            options: [],
          },
          priority: {
            selected: "Bronze",
            options: ["Bronze", "Gold", "Silver", "Platinum"],
          },
          npwpType: {
            selected: null,
            options: ["Personal", "Company"],
          },
          riskCategory: {
            selected: null,
            options: [],
          },
          regionSd: {
            selected: null,
            options: [],
          },
          matrixCustomerGroup: {
            selected: null,
            options: [],
          },
          StatusBlock: null,
          StatusBlockOption: [
            { id: 0, code: "0 - Un-Block" },
            { id: 1, code: "1 - Order Block" },
            { id: 2, code: "2 - Return Block" },
            { id: 3, code: "3 - All Block" }
          ],
        };
      },
      handleDeleteAttachment(id, index) {
        this.data.additionalFile = this.data.additionalFile.filter((item, i) => i != index);
        this.$vs.loading();
        var form = new FormData();
        this.$http
          .delete("/api/oms/v1/sfa-master/customer-attachment/"+id)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.notify("success", "Success", resp.message)
            } else {
              this.notify("danger", "Error", resp.message)
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
          });
      },
      isNumber (evt) {
        const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/'];
        const keyPressed = evt.key;

        if (!keysAllowed.includes(keyPressed)) {
          evt.preventDefault()
        }
      },
      validateForm(result){
        // GENERAL
        if (!this.territory.selected) {
          this.errors.add({
            field: "TerritoryCustomer",
            msg: "The Territory field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('Territory')
        }
        if (!this.salesPersonal.selected) {
          this.errors.add({
            field: "SalesPersonal",
            msg: "The Sales field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('SalesPersonal')
        }
        if (!this.data.name) {
          this.errors.add({
            field: "Name",
            msg: "The Name field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('Name')
        }
        if (this.data.nik.length != 16) {
          this.errors.add({
            field: "NIK",
            msg: "NIK field must be 16 digits.",
          });
          result = false;
          this.tabError = "General"
          console.log('NIK')
        }
        if (!this.matrixCustomerGroup.selected) {
          this.errors.add({
            field: "DistributionChannelMatrix",
            msg: "The Distribution Channel Matirx field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('DistributionChannelMatrix')
        }
        if (this.data.riskCategoryCode == null || this.data.riskCategoryCode == "") {
          this.errors.add({
            field: "RiskCategory",
            msg: "The Risk Category field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('RiskCategory')
        }
        if (!this.data.paymentType) {
          this.errors.add({
            field: "PaymentType",
            msg: "Payment Type field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('PaymentType')
        }
        // if (!this.data.paymentTermId) {
        //   this.errors.add({
        //     field: "PaymentTerm",
        //     msg: "Payment Term field is required",
        //   });
        //   result = false;
        //   console.log('PaymentTerm')
        // }
        if (!this.data.deliveryTypeId) {
          this.errors.add({
            field: "DeliveryType",
            msg: "Delivery Type field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('DeliveryType')
        }
        if (amount < 0) {
          this.errors.add({
            field: "CreditLimit",
            // msg: "The CreditLimit field is must greater than 0",
            msg: "The CreditLimit field cannot negatif value",
          });
          result = false;
          this.tabError = "General"
          console.log('CreditLimit')
        }
        if (this.data.cashLimit == null) {
          this.errors.add({
            field: "CashLimit",
            msg: "The Cash Limit field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('CashLimit')
        }
        if (!this.data.priorityType) {
          this.errors.add({
            field: "Priority",
            msg: "The Priority field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('Priority')
        }
        if (!this.data.regionSdCode) {
          this.errors.add({
            field: "RegionSd",
            msg: "The Region SD field is required",
          });
          result = false;
          this.tabError = "General"
          console.log('RegionSd')
        }

        // ADDRESS
        if (!this.data.address.address) {
          this.data.address.showWarningAddress = true
          this.errors.add({
            field: "Address",
            msg: "The Address field is required",
          });
          result = false;
          this.tabError = "Address"
          console.log('Address')
        }
        if (this.data.rtRw.length != 7 || this.data.rtRw.indexOf("/") != 3 || (this.data.rtRw.split("/").length - 1) > 1) {
          this.errors.add({
            field: "RT/RW",
            msg: "The RT / RW not valid format.",
          });
          result = false;
          this.tabError = "Address"
          console.log('RT/RW')
        }

        // CONTACT
        if (!this.data.contactName) {
          this.errors.add({
            field: "ContactName",
            msg: "The Contact Name field is required",
          });
          result = false;
          this.tabError = "Contact"
          console.log('ContactName')
        }
        if (this.data.mobile.length > 13) {
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field may not be greater than 13 characters.",
          });
          result = false;
          this.tabError = "Contact"
          console.log('Mobile')
        }
        if (this.data.mobile.length < 10) {
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field at least 10 characters.",
          });
          result = false;
          this.tabError = "Contact"
          console.log('Mobile')
        }
        if (this.data.phone.length > 13) {
          this.errors.add({
            field: "Phone",
            msg: "The Phone field may not be greater than 13 characters.",
          });
          result = false;
          this.tabError = "Contact"
          console.log('Phone')
        }
        if (this.data.phone.length < 10) {
          this.errors.add({
            field: "Phone",
            msg: "The Phone field at least 10 characters.",
          });
          result = false;
          this.tabError = "Contact"
          console.log('Phone')
        }

        if (this.data.paymentType == "Credit" && !this.data.isPkp){
          this.notify("danger", "Error", "Customer credit must be PKP true")
          this.data.isPkp = true
        }

        // TAX & INVOICE
        if (this.data.isPkp) {
          if (this.data.npwp.length != 15 && this.data.npwp.length != 16) {
            this.errors.add({
              field: "NPWP",
              msg: "NPWP field must be 15 - 16 digits.",
            });
            result = false;
            this.tabError = "Tax & Invoice"
            console.log('NPWP')
          }
          if (!this.data.npwpName) {
            this.errors.add({
              field: "NpwpName",
              msg: "The NPWP Name field is required",
            });
            result = false;
            this.tabError = "Tax & Invoice"
            console.log('NpwpName')
          }
          if (this.data.paymentType == "Credit"){
            if (!this.data.taxAddress) {
              this.errors.add({
                field: "TaxAddress",
                msg: "The Tax Address field is required",
              });
              result = false;
              this.tabError = "Tax & Invoice"
              console.log('TaxAddress')
            }
            if (!this.data.sppkp) {
              this.errors.add({
                field: "Sppkp",
                msg: "The SPPKP field is required",
              });
              result = false;
              this.tabError = "Tax & Invoice"
              console.log('Sppkp')
            }
          }
        }
        // if (!this.data.domisili) {
        //   this.errors.add({
        //     field: "Domisili",
        //     msg: "The Domisili field is required",
        //   });
        //   result = false;
        //   this.tabError = "Tax & Invoice"
        //   console.log('Domisili')
        // }
        if (this.taxType.selected == null) {
          this.errors.add({
            field: "taxType",
            msg: "The Tax Type is required",
          });
          result = false;
          this.tabError = "Tax & Invoice"
          console.log('taxType ', this.taxType)
        }
        if (!this.data.maxOpenBill) {
          this.errors.add({
            field: "MaxOpenBill",
            msg: "The Max Open Bill field is required",
          });
          result = false;
          this.tabError = "Tax & Invoice"
          console.log('MaxOpenBill ', this.data.maxOpenBill)
        }
        if (!this.data.invoiceModel) {
          this.errors.add({
            field: "InvoiceModel",
            msg: "The Invoice Model field is required",
          });
          result = false;
          this.tabError = "Tax & Invoice"
          console.log('InvoiceModel')
        }
        let amount = 0;
        if (
          this.data.customerLimit.amount != "" ||
          this.data.customerLimit.amount != null
        ) {
          amount = parseInt(
            this.data.customerLimit.amount.toString().replace(/\./g, "")
          );
        }

        return result;
      },
      checkNik(){
        this.$http
          .post("/api/oms/v1/sfa-master/customer/check-nik", {nik: this.data.nik, id: this.customerId})
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.uploadImageAndPostData(0);
            } else {
              this.$vs.dialog({
                type: "confirm",
                color: "primary",
                title: "Confirm NIK Duplicate",
                text: resp.message,
                accept: () => {
                  this.$nextTick(() => {
                    this.uploadImageAndPostData(1);
                  })
                },
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      notify(color, title, message){
        this.$vs.notify({
          color: color,
          title: title,
          text: message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      },
      uploadImageAndPostData(statusDuplicate){
        console.log("masuk kesini")
        var file_ktp = this.data.img_ktp;
        console.log("masuk kesini ktp ori",this.data.img_ktp)
        var file_npwp = this.data.img_npwp;
        var file_sppkp = this.data.img_sppkp;
        var file_invoice = this.data.img_invoice;
        var file_outlet = this.data.img_outlet;
        // var file_cl = this.data.img_cl;
        var file_additionals = this.data.img_additional;
        // if (this.customerId) {
        //   console.log("==== MASUK SINI 2 ====")
        //   console.log("==== PARAM DATA ====", this.paramData())
        //   this.putData();
        // }
        if (statusDuplicate == 1 && (file_additionals == "")) {
          this.notify("danger", "Error", "Additional file required")
        } else {
          console.log("masuk kesini 1")
          console.log("masuk kesini ktp",file_ktp,this.data.urlKtp)
          console.log("masuk kesini outlet",file_outlet,this.data.urlOutlet)
          console.log("masuk kesini npwp",file_npwp,this.data.urlNpwp)
          console.log("masuk kesini sppkp",file_sppkp,this.data.urlSppkp)
          console.log("masuk kesini invoice",file_invoice,this.data.urlInvoice)
          // if (file_ktp == null && this.data.urlKtp == "") {
          //   console.log("masuk kesini 2")
          //   this.notify("danger", "Form Validation", "File KTP Image is required")
          // } else if (file_outlet == null && this.data.urlOutlet == "") {
          //   console.log("masuk kesini 3")
          //   this.notify("danger", "Form Validation", "File Outlet Image is required")
          // } else if (file_npwp == null && this.data.urlNpwp == "") {
          //   console.log("masuk kesini 4")
          //   this.notify("danger", "Form Validation", "File NPWP is required")
          // } else if (this.data.isPkp && (file_sppkp == null && this.data.urlSppkp == "")) {
          //   console.log("masuk kesini 5")
          //   this.notify("danger", "Form Validation", "File SPPKP is required")
          if (file_ktp == null && this.data.urlKtp == "") {
            this.notify("danger", "Form Validation", "File KTP Image is required")
          } else if (file_outlet == null && this.data.urlOutlet == "") {
            this.notify("danger", "Form Validation", "File Outlet Image is required")
          } else if (this.data.isPkp && this.data.paymentType == "Cash" && ((file_npwp == null && this.data.urlNpwp == "") || (file_sppkp == null && this.data.urlSppkp == ""))) {
            this.notify("danger", "Form Validation", "File NPWP and SPPKP is required")
          } else if (this.data.isPkp && this.data.paymentType == "Credit" && ((file_npwp == null && this.data.urlNpwp == "") || (file_sppkp == null && this.data.urlSppkp == "") || (file_invoice == null && this.data.urlInvoice == ""))) {
            this.notify("danger", "Form Validation", "File NPWP, SPPKP, and Invoice is required")
          } else if ((file_ktp != null && this.data.urlKtp != "") || (file_npwp != null && this.data.urlNpwp != "") || (file_sppkp != null && this.data.urlSppkp != "") || (file_outlet != null && this.data.urlOutlet != "")) {
            console.log("masuk kesini 6")
            var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
            var status_image = true
            if (file_ktp != null && !validImageTypes.includes(file_ktp.type)) {
              status_image = false
              this.notify("danger", "Form Validation", "File KTP must be an image")
            }

            if (file_npwp != null && !validImageTypes.includes(file_npwp.type)) {
              status_image = false
              this.notify("danger", "Form Validation", "File NPWP must be an image")
            }

            if (file_sppkp != null && !validImageTypes.includes(file_sppkp.type)) {
              status_image = false
              this.notify("danger", "Form Validation", "File SPPKP must be an image")
            }

            // if (file_invoice != null && !validImageTypes.includes(file_invoice.type)) {
            //   status_image = false
            //   this.notify("danger", "Form Validation", "File INVOICE must be an image")
            // }

            if (file_outlet != null && !validImageTypes.includes(file_outlet.type)) {
              status_image = false
              this.notify("danger", "Form Validation", "File OUTLET must be an image")
            }

            // if (file_cl != null && !validImageTypes.includes(file_cl.type)) {
            //   status_image = false
            //   this.notify("danger", "Form Validation", "File CL must be an image")
            // }
            console.log("masuk kesini 2")
            if (status_image){
              this.formData = new FormData();
              if(file_ktp != null){
                this.formData.append("file_ktp", file_ktp);
              }
              if(file_npwp != null){
                this.formData.append("file_npwp", file_npwp);
              }
              if(file_sppkp != null){
                this.formData.append("file_sppkp", file_sppkp);
              }
              if(file_outlet != null){
                this.formData.append("file_outlet", file_outlet);
              }
              // if(file_invoice != null){
              //   this.formData.append("file_invoice", file_invoice);
              // }
              // if(file_cl != null){
              //   this.formData.append("file_cl", file_cl);
              // }
              for (let key in file_additionals) {
                this.formData.append("file_additional", file_additionals[key]);
              }

              // if(file_additional != null){
              //   this.formData.append("file_additional", file_additional);
              // }

              //new
              this.$vs.loading();
              let customerId = this.customerId ? this.customerId : 0

              console.log("==== CUSTOMER ID ====",customerId)
              if (customerId != 0){
                console.log("==== MASUK SINI ====")
                this.$http
                .post("api/oms/v1/sfa-master/customer-photo/"+customerId, this.formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((resp) => {
                  this.$vs.loading.close();
                  if (resp.status == "success") {
                    // this.handleClose();
                    this.putData();
                  } else {
                    this.notify("danger", "Error", resp.message)
                  }
                });
              }
            }
          }
          else { // jika tidak ada file yg di upload
            if (this.customerId) {
              console.log("==== MASUK SINI 2 ====")
              console.log("==== PARAM DATA ====", this.paramData())
              this.putData();
            }
          }
          console.log("masuk kesini 3")
        }
      },
      handleSubmit() {
        console.log("==== PARAM DATA ====", this.paramData())
        this.$validator.validateAll().then((result) => {
          let res = this.validateForm(result);
          console.log("res ================= ", res)
          if (res) {
            // this.$vs.loading();
            // this.checkNik();
            this.uploadImageAndPostData(0);
            // this.putData();
          } else {
            this.notify("danger", "Error", "Check required field in tab " + this.tabError)
          }
        });
      },
      handleUpdateVirtualAccount(){
        console.log("update virtual account")
        this.$vs.loading();
        let param = {"data": this.data.dataVa}
        this.$http
          .post(this.baseUrl + "-account/" + this.customerId, param)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.notify("success", "Success", resp.message)
            } else {
              this.notify("danger", "Error", resp.message)
              this.$vs.loading.close();
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      handleRemoveItem(indextr) {
        this.data.dataVa.splice(indextr, 1);
        console.log("remove item")
      },
      handleAddItem() {
        this.data.dataVa.push(
          {
            "bank": [],
            "account_number": ""
          }
        )
        console.log("add item")
      },
      paramData() {
        let is_pkp = "2";
        if (this.data.isPkp) {
          is_pkp = "1";
        } else {
          // this.data.npwp = "";
          // this.data.npwpName = "";
          this.data.tdp = "";
          this.data.siup = "";
          this.data.sppkp = "";
          this.data.domisili = "";
          // this.data.taxAddress = "";
        }

        let is_tax_collector = "0";
        if (this.data.isTaxCollector) {
          is_tax_collector = "1";
        }
        let is_tax_exempt = "0";
        if (this.data.isTaxExempt) {
          is_tax_exempt = "1";
        }

        let is_groosup = 0;
        if (this.data.gross_up){
          is_groosup = 1;
        }

        let amount = 0;
        if (this.data.customerLimit.amount != "") {
          amount = parseInt(
            this.data.customerLimit.amount.toString().replace(/\./g, "")
          );
        }

        let salesPersonalId = 0;
        if (this.salesPersonal.selected && this.salesPersonal.selected.ID){
          salesPersonalId = this.salesPersonal.selected.ID;
        } else if(this.salesPersonal.selected && this.salesPersonal.selected.id){
          salesPersonalId = this.salesPersonal.selected.id;
        }
        return {
          npwp_type: this.npwpType.selected,
          matrix_customer_group_id: this.data.matrixCustomerGroupID,
          parent_id: this.data.parentId,
          customer_group_id1: this.data.customerGroupId1,
          customer_group_id2: this.data.customerGroupId2,
          customer_group_id3: this.data.customerGroupId3,
          payment_term_id: this.data.paymentTermId,
          payment_type: this.data.paymentType,
          pricing_group_id: this.data.pricingGroupId,
          payment_method_id: this.data.paymentMethodId,
          customer_category_id: this.data.customerCategoryId,
          delivery_type_id: this.data.deliveryTypeId,
          territory_id: this.territory.selected.ID ? this.territory.selected.ID : this.territory.selected.id,
          sales_personal_id: salesPersonalId,
          code: this.data.code,
          code_external: this.data.codeExternal,
          name: this.data.name,
          legal_name: this.data.legalName,
          search_name: this.data.searchName,
          //zone_id: this.data.customerZone.zoneId,
          country: this.data.address.countryName,
          province: this.data.address.provinceName,
          city: this.data.address.cityName,
          district: this.data.address.districtName,
          sub_district: this.data.address.subdistrictName,
          address: this.data.address.address,
          rt_rw: this.data.rtRw,
          street: this.data.street,
          street_no: this.data.streetNo,
          postal_code: this.data.address.postalCode,
          contact_name: this.data.contactName,
          mobile: this.data.mobile,
          phone: this.data.phone,
          email: this.data.email,
          password: this.data.password,
          fax: this.data.fax === "" ? "" : this.data.fax,
          is_tax_collector: is_tax_collector,
          is_tax_exempt: is_tax_exempt,
          is_pkp: is_pkp,
          npwp: this.data.npwp,
          tdp: this.data.tdp,
          siup: this.data.siup,
          npwp_name: this.data.npwpName,
          sppkp: this.data.sppkp,
          domisili: this.data.domisili,
          tax_address: this.data.taxAddress,
          invoice_model: this.data.invoiceModel,
          status: this.data.status == null ? "Inactive" : this.data.status,
          type: "End Customer",
          priority_type: this.data.priorityType,
          amount: amount,
          nik: this.data.nik,
          nik_name: this.data.nik_name,
          photo: this.data.photo,
          date_of_birth: this.data.dateOfBirth,
          notes: this.data.notes,
          risk_category_code: this.data.riskCategoryCode,
          region_sd_code: this.data.regionSdCode,
          max_open_bill: parseInt(this.data.maxOpenBill),
          tax_type_id: this.taxType.selected ? (this.taxType.selected ? this.taxType.selected.ID : this.taxType.selected.id) : 0,
          latitude: this.data.map.latitude,
          longitude: this.data.map.longitude,
          gross_up: is_groosup,
          status_block: this.StatusBlock.id,
          cash_limit: this.data.cashLimit,
        };
      },
      postData() {
        this.$vs.loading();
        this.$http
          .post("/api/oms/v1/sfa-master/customer-web", this.paramData())
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.notify("success", "Success", "New Customer Created")
            } else {
              this.notify("danger", "Error", resp.message)
              this.$vs.loading.close();
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      getDataTaxType() {
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/tax-type", {
            params: {
              order: "code",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.taxType.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      generateApproval() {
        this.$vs.loading();
        this.$http
          .post(this.baseUrl + "-approval/" + this.customerId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.notify("success", "Success", "Customer Updated")
              this.$vs.loading.close();
            } else {
              this.notify("danger", "Error", resp.message)
              this.$vs.loading.close();
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      putData() {
        // console.log("DATA VA",this.data.dataVa[0].account_number != "")
        this.$vs.loading();
        this.$http
          .put(this.baseUrlUpdate + "/" + this.customerId, this.paramData())
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              if(this.data.dataVa[0].account_number != ""){
                this.handleUpdateVirtualAccount();
              }
              // this.uploadImageAndPostData(0);
              this.notify("success", "Success", "Customer Updated")
              this.$vs.loading.close();
            } else {
              this.notify("danger", "Error", resp.message)
              this.$vs.loading.close();
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      handleClose() {
        window.scrollTo(0, 0);
        this.$emit("close");
      },
      getDataMasterBank() {
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/bank", {
            params: {
              order: "code",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.bank.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getData() {
        this.$vs.loading();
        if (this.customerId) {
          // this.status_disabled = true
          this.$http
            .get(this.baseUrl + "/" + this.customerId)
            .then((resp) => {
              console.log("RESP",resp.data)
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.StatusBlock = this.StatusBlockOption.find(
                  option => option.id === resp.data.status_block
                );
                this.data.reason = resp.data.reason.split("|")
                this.data.maxOpenBill = resp.data.max_open_bill.toString();
                this.data.created_at = resp.data.CreatedAt;
                this.data.parentId = resp.data.parent_id;
                this.data.code = resp.data.code;
                this.data.codeExternal = resp.data.code_external;
                this.data.codeNoo = resp.data.code_noo;
                this.data.statusApproval = resp.data.status_approval;
                this.data.name = resp.data.name;
                this.data.contactName = resp.data.contact_name;
                this.data.phone = resp.data.phone;
                this.data.mobile = resp.data.mobile;
                this.data.email = resp.data.email;
                this.data.fax = resp.data.fax;
                this.data.paymentMethodId = resp.data.payment_method_id;
                this.data.nik = resp.data.nik;
                this.data.nik_name = resp.data.nik_name;
                this.data.photo = resp.data.photo + "?" + new Date().getTime();
                if (resp.data.invoice_model) {
                  this.data.invoiceModel = resp.data.invoice_model;
                }
                if (resp.data.priority_type) {
                  this.priority.selected = resp.data.priority_type;
                }
                if (resp.data.status) {
                  this.data.status = resp.data.status;
                }

                this.data.map.latitude = resp.data.latitude
                this.data.map.longitude = resp.data.longitude
                this.data.address.address = resp.data.address;
                this.data.address.postalCode = resp.data.postal_code;
                this.data.address.countryName = resp.data.country;
                this.data.address.provinceName = resp.data.province;
                this.data.address.cityName = resp.data.city;
                this.data.address.districtName = resp.data.district;
                this.data.address.subdistrictName = resp.data.sub_district;
                this.data.address.countryID = resp.data.country_id;
                this.data.address.provinceID = resp.data.province_id;
                this.data.address.cityID = resp.data.city_id;
                this.data.address.districtID = resp.data.district_id;
                this.data.address.subdistrictID = resp.data.sub_district_id;
                this.data.address.transportZone = resp.data.transport_zone;
                this.data.rtRw = resp.data.rt_rw;
                this.data.street = resp.data.street;
                this.data.streetNo = resp.data.street_no;
                this.data.legalName = resp.data.legal_name;
                this.data.searchName = resp.data.search_name;
                this.data.dateOfBirth = resp.data.date_of_birth;
                if (resp.data.is_tax_collector == "1") {
                  this.data.isTaxCollector = true;
                } else {
                  this.data.isTaxCollector = false;
                }
                if (resp.data.is_tax_exempt == "1") {
                  this.data.isTaxExempt = true;
                } else {
                  this.data.isTaxExempt = false;
                }
                if (resp.data.is_pkp == "1") {
                  this.data.isPkp = true;
                } else {
                  this.data.isPkp = false;
                }

                if (resp.data.gross_up == 1) {
                  this.data.gross_up = true;
                }else {
                  this.data.gross_up = false;
                }

                this.data.npwp = resp.data.npwp;
                this.data.tdp = resp.data.tdp;
                this.data.siup = resp.data.siup;
                this.data.npwpName = resp.data.npwp_name;
                this.data.sppkp = resp.data.sppkp;
                this.data.domisili = resp.data.domisili;
                this.data.taxAddress = resp.data.tax_address;
                this.data.customerLimit.amount = resp.data.customer_limit.amount;
                this.data.note = resp.data.note;
                this.data.riskCategoryCode = resp.data.risk_category_code;
                this.data.regionSdCode = resp.data.region_sd_code;

                this.customerCategory.selected = null
                this.customerGroup1.selected = null;
                this.customerGroup2.selected = null;
                this.customerGroup3.selected = null;
                this.pricingGroup.selected = null

                if (resp.data.matrix_customer_group_id != 0 && resp.data.matrix_customer_group_id != null) {
                  this.data.matrixCustomerGroupID = resp.data.matrix_customer_group_id;
                  let obj = this.matrixCustomerGroup.options.find(o => o.ID === resp.data.matrix_customer_group_id);
                  this.matrixCustomerGroup.selected = obj
                }

                if (resp.data.customer_category_id) {
                  this.getAllDataCustomerGroup1(resp.data.customer_category.name)
                  this.setCustomerCategorySelected(resp.data.customer_category_id);
                }
                if (resp.data.customer_group_id1 && resp.data.customer_group_id2 && resp.data.customer_group_id3 && resp.data.pricing_group_id) {
                  this.setSelectedCustomerGroup(resp.data.customer_group_id1, resp.data.customer_group_id2, resp.data.customer_group_id3, resp.data.pricing_group_id)
                }

                this.loadCreditLimitOri(resp.data.customer_group_id1, resp.data.customer_group_id2, resp.data.customer_group_id3, resp.data.customer_category_id)

                if (resp.data.payment_term_id) {
                  this.setPaymentTermSelected(resp.data.payment_term_id);
                } else {
                  this.paymentTerm.selected = null
                }

                if (resp.data.payment_type) {
                  this.setPaymentTypeSelected(resp.data.payment_type);
                } else {
                  this.data.paymentType = null;
                  this.paymentType.selected = null;
                }

                if (resp.data.delivery_type_id) {
                  this.setDeliveryTypeSelected(resp.data.delivery_type_id);
                } else {
                  this.deliveryType.selected
                }

                if (resp.data.risk_category_code) {
                  this.setRiskCategorySelected(resp.data.risk_category_code);
                } else {
                  this.data.riskCategoryCode = null;
                  this.riskCategory.selected = null;
                }

                if (resp.data.region_sd_code) {
                  this.setRegionSdSelected(resp.data.region_sd_code);
                } else {
                  this.data.regionSdCode = null;
                  this.regionSd.selected = null;
                }

                if (resp.data.tax_type_id) {
                  this.setTaxTypeSelected(resp.data.tax_type_id)
                }

                if (resp.data.npwp_type) {
                  this.npwpType.selected = resp.data.npwp_type
                }

                if (resp.data.customer_address.territory_id) {
                  this.getDataTerritory(resp.data.customer_address.territory_id)
                }

                if (resp.data.customer_address.sales_personal_id) {
                  this.salesPersonal.selected = {id: resp.data.customer_address.sales_personal_id}
                  this.setDataSales(resp.data.customer_address.sales_personal_id)
                }

                var setUrl = this.setUrl;
                resp.data.customer_image.forEach(function (item) {
                  setUrl(item.description, item.photo_url);
                }, setUrl)

                this.data.additionalFile = resp.data.additional_file

                console.log("customer account ", resp.data.customer_account)
                if (resp.data.customer_account != null) {
                  this.data.dataVa = resp.data.customer_account
                }
                this.data.historyNotes = resp.data.history_note
                this.data.cashLimit = resp.data.cash_limit
              } else {
                this.notify("danger", "Error", resp.message)
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        } else {
          this.status_disabled = false
        }
      },
      setSelectedCustomerGroup(cg1Id, cg2Id, cg3Id, pricingId){
        this.$http.get(this.baseUrl + "-group/" + cg1Id).then((resp) => {
          if (resp.status == "success") {
            this.customerGroup1.selected = resp.data;
            this.$http.get(this.baseUrl + "-group/" + cg2Id).then((resp1) => {
              if (resp1.status == "success") {
                this.customerGroup2.selected = resp1.data;
                this.$http.get(this.baseUrl + "-group/" + cg3Id).then((resp2) => {
                  if (resp2.status == "success") {
                    this.customerGroup3.selected = resp2.data;
                    this.$http.get("/api/oms/v1/sfa-master/pricing-group/" + pricingId).then((resp3) => {
                      if (resp3.status == "success") {
                        this.pricingGroup.selected = resp3.data;
                      }
                    });
                  }
                });
              }
            });
          }
        })
      },
      getDataMatrixCustomerGroup() {
        this.$vs.loading();
        this.$http
          .get(this.baseUrlMatrixCustomerGroup, {
            params: {
              order: "hierarchy_code",
              sort: "asc",
              allow_sfa: "2",
              source: ""
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.matrixCustomerGroup.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      // getAllDataCustomerCategory() {
      //   this.$vs.loading();
      //   this.$http
      //     .get(this.baseUrl + "-category-all", {
      //       params: {
      //         order: "name",
      //         sort: "asc",
      //         is_mobile: true
      //       },
      //     })
      //     .then((resp) => {
      //       if (resp.status == "success") {
      //         this.customerCategory.options = resp.data;
      //         this.$vs.loading.close();
      //       } else {
      //         this.$vs.loading.close();
      //       }
      //     });
      // },
      getAllDataCustomerGroup1(customer_category_name="") {
        console.log("getAllDataCustomerGroup1");
        this.$vs.loading();
        this.$http
        .get(this.baseUrlMatrixCg, {
          params: {
            allow_sfa: 2,
            level: 1,
            customer_category_name: customer_category_name,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup1.options = resp.data.records;
            if (this.matrixCustomerGroup.selected != null) {
              let obj = resp.data.records.find(o => o.code === this.matrixCustomerGroup.selected.cg1_code);
              this.customerGroup1.selected = obj
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      getAllDataCustomerGroup2(cg1_code="") {
        console.log("getAllDataCustomerGroup2");
        this.$vs.loading();
        this.$http
          .get(this.baseUrlMatrixCg, {
            params: {
              allow_sfa: 2,
              level: 2,
              cg1_code: cg1_code,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.customerGroup2.options = resp.data.records;
              if (this.matrixCustomerGroup.selected != null) {
                let obj = resp.data.records.find(o => o.code === this.matrixCustomerGroup.selected.cg2_code);
                this.customerGroup2.selected = obj
              }
              console.log(this.customerGroup2.options);
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },

      getAllDataCustomerGroup3(cg1_code="",cg2_code="") {
        console.log("getAllDataCustomerGroup3");
        this.$vs.loading();
        this.$http
          .get(this.baseUrlMatrixCg, {
            params: {
              allow_sfa: 2,
              level: 3,
              cg1_code: cg1_code,
              cg2_code: cg2_code,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.customerGroup3.options = resp.data.records;
              if (this.matrixCustomerGroup.selected != null) {
                let obj = resp.data.records.find(o => o.code === this.matrixCustomerGroup.selected.cg3_code);
                this.customerGroup3.selected = obj
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getAllDataPricingGroup(cg1_code="",cg2_code="",cg3_code="") {
        console.log("getAllDataPricingGroup")
        this.$vs.loading();
        this.$http
          .get(this.baseUrlMatrixCg, {
            params: {
              allow_sfa: 2,
              level: 4,
              cg1_code: cg1_code,
              cg2_code: cg2_code,
              cg3_code: cg3_code,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.pricingGroup.options = resp.data.records;
              if (this.matrixCustomerGroup.selected != null) {
                let obj = resp.data.records.find(o => o.code === this.matrixCustomerGroup.selected.pricing_code);
                this.pricingGroup.selected = obj
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getAllDataPaymentTerm() {
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/payment-term", {
            params: {
              order: "name",
              sort: "asc",
              //payment_method_id: this.data.paymentMethodId,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.paymentTerm.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getDataTerritory(id) {
        this.$vs.loading();
        this.$http.get("/api/oms/v1/setting-user/territory/" + id).then((resp) => {
          if (resp.status == "success") {
            this.territory.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      getAllDataDeliveryType() {
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/delivery-type", {
            params: {
              order: "code",
              sort: "asc",
              type: "Sales",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.deliveryType.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getAllDataRiskCategory() {
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/customer-risk-category")
          .then((resp) => {
            if (resp.status == "success") {
              this.riskCategory.options = resp.data;
              console.log("risk category ", resp.data)
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getAllDataRegionSd() {
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/region-sd")
          .then((resp) => {
            if (resp.status == "success") {
              this.regionSd.options = resp.data;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getDataTerritories() {
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/setting-user/territory-master", {
            params: {
              order: "code",
              sort: "asc",
              type: "Sales",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.territory.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getDataSales(territoryID) {
        this.$vs.loading();
        let url = "/api/oms/v1/sfa-master/customer-sales/territory/"
        if (this.data.ccName == "IT"){
          url = "/api/oms/v1/sfa-master/customer-bm/territory/"
        }
        this.$http
          .get(url + territoryID)
          .then((resp) => {
            if (resp.status == "success") {
              this.salesPersonal.options = resp.data;
              console.log("get data sales ", resp.data)
              if (this.salesPersonal.selected != null) {
                let obj = resp.data.find(o => o.ID === this.salesPersonal.selected.id);
                console.log("id selected ", this.salesPersonal.selected.id)
                console.log("Sales selected 1 ", obj)
                this.salesPersonal.selected = obj
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      setDataSales(selectedSalesPersonalID) {
        if (this.salesPersonal.options.length > 0) {
          let obj = this.salesPersonal.options.find(o => o.ID === selectedSalesPersonalID);
          console.log("Sales selected ", obj)
          this.salesPersonal.selected = obj
        }
      },
      // getDataZone(ids) {
      //   this.$vs.loading();
      //   this.$http
      //     .get("/api/v1/master/zone/territory", {
      //       params: {
      //         territory_ids: ids,
      //       },
      //     })
      //     .then((resp) => {
      //       if (resp.status == "success") {
      //         this.zone.options = resp.data;
      //         this.$vs.loading.close();
      //       } else {
      //         this.$vs.loading.close();
      //       }
      //     });
      // },
      setUrl(type, url) {
        if(type == "ktp"){
          this.data.urlKtp = url
        } else if(type == "npwp"){
          this.data.urlNpwp = url
        } else if(type == "sppkp"){
          this.data.urlSppkp = url
        } else if(type == "outlet"){
          this.data.urlOutlet = url
        } else if(type == "invoice"){
          this.data.urlInvoice = url
        } else if(type == "cl"){
          this.data.urlCl = url
        }

      },
      setCustomerCategorySelected(id) {
        this.$vs.loading();
        this.$http.get(this.baseUrl + "-category/" + id).then((resp) => {
          if (resp.status == "success") {
            this.customerCategory.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setPricingGroupSelected(id) {
        this.$vs.loading();
        this.$http.get("/api/oms/v1/sfa-master/pricing-group/" + id).then((resp) => {
          if (resp.status == "success") {
            this.pricingGroup.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setCustomerGroup1Selected(id) {
        this.$vs.loading();
        this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
          if (resp.status == "success") {
            this.customerGroup1.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setCustomerGroup2Selected(id) {
        this.$vs.loading();
        this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
          if (resp.status == "success") {
            this.customerGroup2.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setCustomerGroup3Selected(id) {
        this.$vs.loading();
        this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
          if (resp.status == "success") {
            this.customerGroup3.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setPaymentTermSelected(id) {
        this.$vs.loading();
        this.$http.get("/api/oms/v1/sfa-master/payment-term/" + id).then((resp) => {
          if (resp.status == "success") {
            this.paymentTerm.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setTaxTypeSelected(id) {
        this.$vs.loading();
        this.$http.get("/api/oms/v1/sfa-master/tax-type/" + id).then((resp) => {
          if (resp.status == "success") {
            this.taxType.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setPaymentTypeSelected(val) {
        if (val == "cash") {
          this.statusReadonlyCL = true
          this.classCL += " readonly"
        } else {
          this.statusReadonlyCL = false
          this.classCL = "w-full"
        }
        this.data.paymentType = val;
        this.paymentType.selected = val;
      },
      // setZoneSelected(id) {
      //   this.$vs.loading();
      //   this.$http.get("/api/v1/master/zone/" + id).then((resp) => {
      //     if (resp.status == "success") {
      //       this.zone.selected = resp.data;
      //       this.$vs.loading.close();
      //     } else {
      //       this.$vs.loading.close();
      //     }
      //   });
      // },
      setDeliveryTypeSelected(id) {
        this.$vs.loading();
        this.$http.get("/api/oms/v1/sfa-master/delivery-type/" + id).then((resp) => {
          if (resp.status == "success") {
            this.deliveryType.selected = resp.data[0];
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setRiskCategorySelected(val) {
        this.data.riskCategoryCode = val;
        let obj = this.riskCategory.options.find(o => o.code === val);
        this.riskCategory.selected = obj;
        console.log("selected risk category ", obj)
      },
      setRegionSdSelected(val) {
        this.data.regionSdCode = val;
        this.regionSd.selected = {"code": val};
      },
      setPaymentMethodId(val) {
        this.data.paymentMethodId = val;
      },
      setParentId(val) {
        this.data.parentId = val;
      },
      setStatus(val) {
        this.data.status = val;
      },
      setDataAddress(val) {
        this.data.address = val;
        this.data.taxAddress = this.data.address.address;
      },
      autoFormatNPWP(NPWPString) {
        try {
          if (this.data.npwp.length == 21) {
            this.data.npwp = this.data.npwp.slice(0, -1)
          } else {
            var cleaned = ("" + NPWPString.toString()).replace(/\D/g, "");
            var match = cleaned.match(/(\d{0,2})?(\d{0,3})?(\d{0,3})?(\d{0,1})?(\d{0,3})?(\d{0,3})$/);
            this.data.npwp = [match[1],match[2] ? ".": "",match[2], match[3] ? ".": "",match[3],match[4] ? ".": "",match[4],match[5] ? "-": "",match[5],match[6] ? ".": "",match[6]].join("")
          }
        } catch(err) {
          return "";
        }
      },
      loadCreditLimitOri(cg1, cg2, cg3, ccId){
        this.$vs.loading();
        this.$http
        .get("/api/oms/v1/sfa-master/customer-group-limit/payment-term", {
          params: {
            customer_group_id1: cg1,
            customer_group_id2: cg2,
            customer_group_id3: cg3,
            customer_category_id: ccId,
          },
        })
        .then((resp) => {
          console.log("sukses 1",resp)
          if (resp.status == "success") {
            console.log("credit limit ori 1",resp.data[0].records.credit_limit)
            this.credit_limit_ori = resp.data[0].records.credit_limit
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      loadPaymentTermMatrix(){
        if (
          this.data.customerGroupId1 != null &&
          this.data.customerGroupId2 != null &&
          this.data.customerGroupId3 != null
        ) {
          this.$vs.loading();
          this.$http
          .get("/api/oms/v1/sfa-master/customer-group-limit/payment-term", {
            params: {
              customer_group_id1: this.data.customerGroupId1,
              customer_group_id2: this.data.customerGroupId2,
              customer_group_id3: this.data.customerGroupId3,
              customer_category_id: this.data.customerCategoryId,
            },
          })
          .then((resp) => {
            console.log("sukses 2",resp)
            if (resp.status == "success") {
              console.log("credit limit ori 2",resp.data[0].records.credit_limit)
              this.credit_limit_ori = resp.data[0].records.credit_limit
              this.paymentTerm.selected = resp.data[0].payment_term;
              this.data.cashLimit = resp.data[0].records.cash_limit;
              if(this.data.paymentType == "Credit"){
                this.data.customerLimit.amount =
                  resp.data[0].records.credit_limit
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
        }
      }
    },
    mounted() {
      // this.getAllDataCustomerCategory();
      this.getAllDataPaymentTerm();
      this.getAllDataDeliveryType();
      this.getAllDataRiskCategory();
      this.getAllDataRegionSd();
      this.getDataMatrixCustomerGroup();
      this.getDataTaxType();
      this.getDataTerritories();
      this.getDataMasterBank();
      if (this.customerId) {
        this.getData()
        // this.status_disabled = true
      } else {
        this.status_disabled = false
      }
    },
    computed: {},
    watch: {
      customerId(v) {
        // this.getAllDataCustomerCategory();
        this.getAllDataPaymentTerm();
        this.getAllDataDeliveryType();
        this.getAllDataRiskCategory();
        this.getAllDataRegionSd();
        this.getDataMatrixCustomerGroup();
        this.getDataTaxType();
        this.getDataTerritories();
        this.getDataMasterBank();
        if (v) {
          this.credit_limit_ori = 0
          this.getData();
        } else {
          Object.assign(this.$data, this.initialState());
        }
      },
      "matrixCustomerGroup.selected" : function (val) {
        if (val) {
          this.errors.clear();
          this.data.customerCategoryId = val.customer_category_id
          this.data.matrixCustomerGroupID = val.id ? val.id : val.ID;
          this.customerGroup1.selected = null
          this.customerGroup2.selected = null
          this.customerGroup3.selected = null
          this.pricingGroup.selected = null
          // this.riskCategory.selected = null

          if (val.customer_category_name == "IT") {
            this.showBm = true
            this.data.ccName = val.customer_category_name
            this.getDataSales(this.territory.selected.ID ? this.territory.selected.ID : this.territory.selected.id)
          } else {
            this.showBm = false
          }

          console.log("customer category name : ", val.customer_category_name);
          this.getAllDataCustomerGroup1(val.customer_category_name)
        } else {
          this.data.customerCategoryId = null;
        }
      },
      "pricingGroup.selected": function (val) {
        if (val) {
          this.errors.clear();
          this.data.pricingGroupId = val.id ? val.id : val.ID;
        } else {
          this.data.pricingGroupId = null;
        }
      },
      "customerGroup1.selected": function (val) {
        if (val) {
          this.errors.clear();
          this.data.customerGroupId1 = val.id ? val.id : val.ID;
          console.log("TESSSSSSS 1")
          this.loadPaymentTermMatrix();
          if (this.data.customerCategoryId != null){
            this.customerGroup2.selected = null
            this.customerGroup3.selected = null
            this.pricingGroup.selected = null
            this.$http
            .get("/api/oms/v1/sfa-master/risk-category/" + val.risk_category_id)
            .then((resp) => {
              if (resp.status == "success") {
                if (resp.data.ID != 0) {
                  this.riskCategory.selected = resp.data
                }
              }
            });
          }
          console.log("customer group 1 code : ", val.code);
          this.getAllDataCustomerGroup2(val.code)
        } else {
          this.data.customerGroupId1 = null;
        }
      },
      "customerGroup2.selected": function (val) {
        if (val) {
          this.errors.clear();
          this.data.customerGroupId2 = val.id ? val.id : val.ID;
          this.loadPaymentTermMatrix();
          if (this.customerGroup1.selected != null){
            this.customerGroup3.selected = null
            this.pricingGroup.selected = null
            console.log("customer group 1 code : ", this.customerGroup1.selected.code);
            console.log("customer group 2 code : ", val.code);
            this.getAllDataCustomerGroup3(this.customerGroup1.selected.code, val.code)
          }
        } else {
          this.data.customerGroupId2 = null;
        }
      },
      "customerGroup3.selected": function (val) {
        if (val) {
          this.errors.clear();
          this.data.customerGroupId3 = val.id ? val.id : val.ID;
          this.loadPaymentTermMatrix();
          if (this.customerGroup1.selected != null && this.customerGroup2.selected != null){
            this.pricingGroup.selected = null
            console.log("customer group 1 code : ", this.customerGroup1.selected.code);
            console.log("customer group 2 code : ", this.customerGroup2.selected.code);
            console.log("customer group 3 code : ", val.code);
            this.getAllDataPricingGroup(this.customerGroup1.selected.code, this.customerGroup2.selected.code, val.code)
          }
        } else {
          this.data.customerGroupId3 = null;
        }
      },
      "paymentTerm.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.paymentTermId = val.id ? val.id : val.ID;
          this.data.paymentMethodId = val.payment_method_id;
        } else {
          this.data.paymentTermId = null;
        }
      },
      "data.paymentMethodId": function (val) {
        if (this.paymentTerm.selected && val !== undefined) {
          if (this.paymentTerm.selected.payment_method_id != val) {
            this.paymentTerm.selected = null;
          }
        }
        this.getAllDataPaymentTerm();
      },
      "deliveryType.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.deliveryTypeId = val.id ? val.id : val.ID;
        } else {
          this.data.deliveryTypeId = null;
        }
      },
      "priority.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.priorityType = val;
        } else {
          this.data.priorityType = null;
        }
      },
      "paymentType.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.paymentType = val;

          if (val == "Cash") {
            let obj = this.paymentTerm.options.find(o => o.due_invoice_period === 0);
            this.paymentTerm.selected = obj
            this.data.customerLimit.amount = 0;
            this.statusReadonlyCL = true
            this.classCL += " readonly"
            // this.data.isPkp = false
          } else {
            this.statusReadonlyCL = false
            this.classCL = "w-full"
            // this.data.isPkp = true
            this.loadPaymentTermMatrix();
          }
        } else {
          this.data.paymentType = null;
        }
      },
      "riskCategory.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.riskCategoryCode = val.code;
        } else {
          this.data.riskCategoryCode = null;
        }
      },
      "regionSd.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.regionSdCode = val.code;
        } else {
          this.data.regionSdCode = null;
        }
      },
      "data.maxOpenBill": function () {
        if (this.data.maxOpenBill) {
          this.errors.clear();
        }
      },
      "data.rtRw": function (val) {
        if (val.length == 8) {
          this.data.rtRw = this.data.rtRw.slice(0, -1)
        }
        if (val.length < 7 || val.indexOf("/") != 3 || (val.split("/").length - 1) > 1) {
          this.errors.add({
            field: "RT/RW",
            msg: "The RT / RW not valid format.",
          });
        } else if (val.length == 7) {
          this.errors.clear();
        }
      },
      "data.customerLimit.amount": function (val) {
        if (val != null) {
          val = val.toString().replace(/\./g, "");
          if (isNaN(parseInt(val))) {
            this.errors.add({
              field: "CreditLimit",
              msg: "The CreditLimit field is number required",
            });
          } else {
            this.errors.clear();
          }
          if (val > this.credit_limit_ori && this.credit_limit_ori != 0) {
            console.log(val)
            this.data.customerLimit.amount = this.credit_limit_ori
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            this.notify("danger", "Error", "Credit Limit cannot more than value matrix (" + this.data.customerLimit.amount + ")")
          } else {
            this.data.customerLimit.amount = val
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          }
        } else {
          this.data.customerLimit.amount = 0;
        }
      },
      "data.npwp": function () {
        if (this.data.isPkp && this.data.npwp == '') {
          this.errors.clear();
          this.errors.add({
            field: "NPWP",
            msg: "The NPWP field is required",
          });
        } else if (this.data.isPkp && this.data.npwp.length != 16 && this.data.npwp.length != 15) {
          this.errors.clear();
          this.errors.add({
            field: "NPWP",
            msg: "The NPWP field is must be 15 - 16 digits",
          });
        } else {
          this.errors.clear();
        }
      },
      "data.taxAddress": function () {
        if (this.data.isPkp && !this.data.taxAddress) {
          this.errors.add({
            field: "TaxAddress",
            msg: "The Tax Address field is required",
          });
        } else {
          this.errors.clear();
        }
      },
      "data.paymentType": function (val) {
        // if (val != null && val == 'Cash') {
        //   let obj = this.paymentTerm.options.find(o => o.due_invoice_period === 0);
        //   if (obj) {
        //     this.paymentTerm.selected = obj;
        //     this.data.paymentTermId = obj.id ? obj.id : obj.ID;
        //     this.data.customerLimit.amount = 1;
        //   }
        // } else if(val != null && val == 'Credit'){
        //   this.paymentTerm.selected = this.data.paymentTermMatrix
        //   this.data.paymentTermId = this.data.paymentTermMatrix.ID ? this.data.paymentTermMatrix.ID : this.data.paymentTermMatrix.id;
        //   this.data.customerLimit.amount = this.data.creditLimitMatrix
        // }
      },
      "territory.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          // let id = val.id ? val.id : val.ID
          this.getDataSales(val.id ? val.id : val.ID)
        }
      },
      "taxType.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
        }
      },
    },
  };
</script>
<style >
.ps {
  height: 800px;
}

.readonly > div > input {
  background-color: #ebebeb;
}

.vs-dialog {
  max-width: 50% !important;
  max-height: 70% !important;
}
</style>
